import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c(VAppBar,{attrs:{"color":"light"}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = true}}}),_c(VImg,{staticClass:"mx-2",attrs:{"max-width":"150","src":"/small-icon.png"}}),_c(VToolbarTitle,{staticClass:"light-blue--text text--darken-4"},[_vm._v(" Inicio ")])],1),_c(VNavigationDrawer,{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c(VListItem,{on:{"click":function($event){return _vm.openLinkMenu('/')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-home")])],1),_c(VListItemTitle,[_vm._v("Inicio")])],1),_c(VListItem,{on:{"click":function($event){return _vm.openLinkMenu('/customers')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account")])],1),_c(VListItemTitle,[_vm._v("Clientes")])],1),_c(VListItem,{on:{"click":function($event){return _vm.openLinkMenu('/reports/report-sales')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-currency-usd")])],1),_c(VListItemTitle,[_vm._v("Reporte de global de caja")])],1),_c(VListItem,{on:{"click":function($event){return _vm.openLinkMenu('/reports/report-payments-sales')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-cash-multiple")])],1),_c(VListItemTitle,[_vm._v("Reportes de pagos de caja")])],1),_c(VListItem,{on:{"click":function($event){return _vm.openLinkMenu('/reports/report-commissions')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-cash-multiple")])],1),_c(VListItemTitle,[_vm._v("Reportes de comisiones")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }