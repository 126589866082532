<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <p class="text-h4 text--secondary font-weight-bold">
          Reporte de caja
        </p>
      </v-card-title>
      <v-card-subtitle>
        <p>Módulo para visualizar un reporte de caja dado un rango de fechas</p>
      </v-card-subtitle>
      <v-card-text>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ReportCommissions",
  data() {
    return {
      loadingResults: false,
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>