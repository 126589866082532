<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <p class="text-h4 text--secondary font-weight-bold">
          Reporte de ingresos de caja
        </p>
      </v-card-title>
      <v-card-subtitle>
        <p>Módulo para visualizar un reporte detallado de los ingresos en caja por diferentes métodos de pago y tipo de moneda dado un rango de fechas</p>
      </v-card-subtitle>
      <v-card-text>
        <v-alert color="black" class="white--text">Especifique un rango de fechas para el reporte de caja</v-alert>
        <v-row>
          <v-col cols="2">
            <v-text-field v-model="reportDates.startDate" label="Fecha de inicio del reporte" type="date"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="reportDates.endDate" label="Fecha de término del reporte" type="date"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select @change="cleanFilter()" label="Método de pago" v-model="reportDates.paymentsMethods" :items="itemsFilterPaymentMethods" item-value="id" item-text="payment_method_name" return-object></v-select>
          </v-col>
          <v-col cols="2">
            <v-select @change="cleanFilter()" label="Moneda" v-model="reportDates.currencies" :items="itemsCurrenciesList" item-value="id" item-text="currency_iso" return-object></v-select>
          </v-col>
          <v-col cols="4">
            <v-btn :disabled="loadingResults" x-large color="black" class="white--text" @click="getPaymentsSalesReport">Confirmar</v-btn>
          </v-col>
        </v-row>


        <div v-if="loadingResults">
          <v-progress-linear
              indeterminate
              color="blue"
              class="mb-5"
          ></v-progress-linear>
        </div>
        <div v-else>
          <div v-if="paymentsItems && paymentsItems.length > 0">
            <div class="report-details pl-4">
              <p class="grey--text text--darken-4 text-h4">Detalles del reporte</p>
              <p class="grey--text text--darken-2 text-h6">Fechas del <strong>{{ moment(reportDates.startDate).format('LL') }}</strong> al <strong>{{moment(reportDates.endDate).format('LL')}}</strong></p>
              <p class="grey--text text--darken-2 text-h6"><strong>Método de pago:</strong> {{reportDates.paymentsMethods.payment_method_name}}</p>
              <p class="grey--text text--darken-2 text-h6"><strong>Moneda:</strong> {{reportDates.currencies.currency_iso}}</p>
            </div>

            <v-simple-table>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Resultados del reporte de caja por método de pago y moneda</v-toolbar-title>
                </v-toolbar>
              </template>
              <thead>
              <tr>
                <th>#</th>
                <th>Método de pago</th>
                <th>Moneda del pago</th>
                <th>Conversión usada MXN</th>
                <th>Monto en la moneda del pago</th>
                <th>Monto pagado en MXN</th>
                <th>Cantidad proporcionada</th>
                <th>Cambio calculado en la moneda del pago</th>
                <th>Cambio devuelto (MXN)</th>
                <th>Fecha del pago</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="itemPayment in paymentsItems">
                <td>{{itemPayment.id}}</td>
                <td>{{itemPayment.payment_method.payment_method_name}}</td>
                <td>{{itemPayment.currency.currency_iso}}</td>
                <td>{{itemPayment.change_currency.current_amount_mxn}}</td>
                <td>{{itemPayment.amount_payment}}</td>
                <td>{{ (itemPayment.currency.id !== 1) ? itemPayment.amount_payment_converted_mxn : 'No Aplica' }}</td>
                <td>{{itemPayment.cash_provided}}</td>
                <td>{{itemPayment.cash_change_calculated}}</td>
                <td>{{ (itemPayment.currency.id !== 1) ? itemPayment.cash_change_calculated_mxn : 'No Aplica' }}</td>
                <td>{{ moment(itemPayment.created_at).format('LLL') }}</td>
              </tr>
              </tbody>
            </v-simple-table>


            <v-simple-table>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Totales del corte de caja:</v-toolbar-title>
                </v-toolbar>
              </template>
              <tbody>
              <tr>
                <td>Ingresos en efectivo en MXN: </td>
                <td>{{ number_format(totalsBrief.amount_cash_only_mxn, 2, '.', ',') }}</td>
              </tr>
              <tr>
                <td>Ingresos de Tarjeta de Débito (MXN): </td>
                <td>{{ number_format(totalsBrief.amount_debit_card_mxn, 2, '.', ',') }}</td>
              </tr>
              <tr>
                <td>Ingresos de Tarjeta de Crédito (MXN): </td>
                <td>{{ number_format(totalsBrief.amount_credit_card_mxn, 2, '.', ',') }}</td>
              </tr>
              <tr>
                <td colspan="2"><p class="mt-2 mb-2 grey--text text--darken-2 text-h6">Ingresos en otras monedas</p></td>
              </tr>

              <tr>
                <td>Ingresos en efectivo en Dólares (USD): </td>
                <td>{{ number_format(totalsBrief.amount_cash_only_usd, 2, '.', ',') }}</td>
              </tr>
              <tr>
                <td>Ingresos en efectivo en Euros (EUR): </td>
                <td>{{ number_format(totalsBrief.amount_cash_only_eur, 2, '.', ',') }}</td>
              </tr>

              <tr>
                <td colspan="2"><p class="mt-2 mb-2 grey--text text--darken-2 text-h6">Egresos en MXN</p></td>
              </tr>

              <tr>
                <td>Cambios devueltos en MXN por pago en otras monedas: </td>
                <td>{{ number_format(totalsBrief.amount_cash_change_calculated_mxn, 2, '.', ',') }}</td>
              </tr>

              <tr>
                <td colspan="2"><p class="mt-2 mb-2 grey--text text--darken-2 text-h6">Otros egresos</p></td>
              </tr>

              <tr>
                <td>Pagos por visita: </td>
                <td>{{ number_format(totalsBrief.amount_guide_payments_visits_mxn, 2, '.', ',') }}</td>
              </tr>

              </tbody>
            </v-simple-table>
          </div>
          <div v-else>
            Por favor especifique un filtro para continuar.
          </div>
        </div>


      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "ReportPaymentsSales",
  data() {
    return {
      totalsBrief: null,
      itemsCurrenciesList: [],
      itemsFilterPaymentMethods: [],
      reportDates: {
        startDate: '2024-06-27',
        endDate: '2024-07-01',
        paymentsMethods: null,
        currencies: null,
      },
      loadingResults: false,
      paymentsItems: null,
    }
  },
  mounted() {
    this.getPaymentMethodsList()
    this.getCurrenciesList()
  },
  methods: {
    number_format(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
    sumValuesInArray(array, keyToSum) {
      const total = array.reduce((accumulator, object) => {
        return accumulator + object[keyToSum];
      }, 0);

      return total;
    },
    cleanFilter(){
      this.paymentsItems = []
    },
    getCurrenciesList(){
      this.itemsCurrenciesList = []
      this.$http.get(`${this.$apiUrl}/currencies`)
          .then((res) => {

            this.itemsCurrenciesList = res.data.data
            this.itemsCurrenciesList = [{
              id: 0,
              currency_iso: 'Todos'
            }, ...this.itemsCurrenciesList]
          })
          .finally(() => {
            this.reportDates.currencies = (this.itemsCurrenciesList[0])
          })
    },
    getPaymentMethodsList(){
      this.itemsFilterPaymentMethods = []
      this.$http.get(`${this.$apiUrl}/payment-methods`)
          .then((res) => {

            this.itemsFilterPaymentMethods = res.data.data
            this.itemsFilterPaymentMethods = [{
              id: 0,
              payment_method_name: 'Todos'
            }, ...this.itemsFilterPaymentMethods]
          })
          .finally(() => {
           this.reportDates.paymentsMethods = (this.itemsFilterPaymentMethods[0])
          })
    },
    getPaymentsSalesReport(){
      if(
          this.reportDates.startDate.trim().length <= 0||
          this.reportDates.endDate.trim().length <= 0
      ) {
        alert('Favor de ingresar fechas válidas')
        return false
      }
      this.paymentsItems = []

      this.loadingResults = true
      this.$http.post(`${this.$apiUrl}/reports-manager/get-payments-shopping-cart`, {
        'date-from': this.reportDates.startDate,
        'date-to': this.reportDates.endDate,
        'payments_methods': this.reportDates.paymentsMethods,
        'currencies': this.reportDates.currencies,
      })
          .then((res) => {
            this.paymentsItems = res.data.data.report_details
            this.totalsBrief = res.data.data.totals
          })
          .finally(() => {
            this.loadingResults = false
          })
    },
  },
}
</script>