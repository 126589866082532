import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CustomersHome from "@/views/views/customers/CustomersHome.vue";
import ReportSales from "@/views/views/reports/ReportSales.vue";
import ReportCommissions from "@/views/views/reports/ReportCommissions.vue";
import ReportPaymentsSales from "@/views/views/reports/ReportPaymentsSales.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/customers',
    name: 'customers',
    component: CustomersHome
  },
  {
    path: '/reports/report-sales',
    name: 'reportsales',
    component: ReportSales
  },
  {
    path: '/reports/report-commissions',
    name: 'reportcommissions',
    component: ReportCommissions
  },
  {
    path: '/reports/report-payments-sales',
    name: 'reportpaymentssales',
    component: ReportPaymentsSales
  },
  /*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/!* webpackChunkName: "about" *!/ '../views/AboutView.vue')
    }
  }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
