<template>
  <div>
    <v-card class="mt-3">
      <v-card-title>
        <p class="text-h4 text--secondary font-weight-bold">
          Reporte global de caja
        </p>
      </v-card-title>
      <v-card-subtitle>
        <p>Módulo para visualizar un reporte general de caja con desglose de pago, comisiones, entre otros datos, dado un rango de fechas</p>
      </v-card-subtitle>
      <v-card-text>
        <v-alert color="black" class="white--text">Especifique un rango de fechas para el reporte de caja</v-alert>
        <v-row>
          <v-col cols="2">
            <v-text-field v-model="reportDates.startDate" label="Fecha de inicio del reporte" type="date"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="reportDates.endDate" label="Fecha de término del reporte" type="date"></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-btn x-large color="black" class="white--text" @click="getSalesReport">Confirmar</v-btn>
          </v-col>
        </v-row>

        <v-data-table
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            :loading="loadingResults"
            :headers="headersReport"
            :items="itemsReport">
<!--          -->

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Resultados del reporte global de caja</v-toolbar-title>
            </v-toolbar>
          </template>

          <template v-slot:item.invoice_string_id="{  item }">
            <div>
              <a :href="getInvoicePdfUrl(item)" target="_blank" v-html="item.invoice_string_id"></a>
            </div>
          </template>

          <template v-slot:item.created_at="{  item }">
            <div>
              {{moment(item.created_at).format('LLL')}}
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <p class="text-h6">Detalles de la compra</p>
              <v-simple-table>
                <tbody>
                <tr>
                  <td>Subtotal</td>
                  <td>{{item.subamount_mxn}}</td>
                </tr>
                <tr>
                  <td>Impuestos</td>
                  <td>{{item.taxes_mxn}}</td>
                </tr>
                <tr>
                  <td>Totales</td>
                  <td>{{item.amount_mxn}}</td>
                </tr>
                <tr>
                  <td>¿Se generó comisión de venta?</td>
                  <td>{{ (item.guide_commission_amount > 0) ? 'Sí' : 'No' }}</td>
                </tr>
                <tr v-if="(item.guide_commission_amount > 0)">
                  <td>Detalles de la comisión</td>
                  <td>
                    <table class="inner-table" style="width: 100%;">
                      <tr>
                        <td>Monto comisión:</td>
                        <td>{{ item.guide_commission_amount }}</td>
                      </tr>
                      <tr>
                        <td>Guía o agencia:</td>
                        <td>{{ item.guide_sale_linked.guide_name }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>Monto pagado en otra moneda</td>
                  <td v-if="(item.currency.id===1)">
                    No Aplica
                  </td>
                  <td v-else>
                    <table class="inner-table" style="width: 100%;">
                      <tr>
                        <td>Moneda:</td>
                        <td>{{item.currency.currency_iso}}</td>
                      </tr>
                      <tr v-if="item.currency.id !== 1">
                        <td>Tipo de cambio usado (MXN):</td>
                        <td>{{item.change_currency.current_amount_mxn}}</td>
                      </tr>
                      <tr>
                        <td>Subtotal:</td>
                        <td>{{item.subamount_converted}}</td>
                      </tr>
                      <tr>
                        <td>Impuestos:</td>
                        <td>{{item.taxes_converted}}</td>
                      </tr>
                      <tr>
                        <td>Total pagado en {{item.currency.currency_iso}}:</td>
                        <td>{{item.amount_converted}}</td>
                      </tr>
                      <tr>
                        <td>Total pagado en MXN:</td>
                        <td>{{item.amount_mxn}}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>Formas de pago:
                  </td>
                  <td>
                    <table class="inner-table" style="width:100%;">
                      <template v-for="(itemPayment, index) in item.invoice_payments">
                        <tr>
                          <td colspan="2"><strong>Pago #{{index+1}}</strong></td>
                        </tr>
                        <tr>
                          <td>Método de pago:</td>
                          <td>{{itemPayment.payment_method.payment_method_name}}</td>
                        </tr>
                        <template >
                          <tr>
                            <td>Monto pagado ({{ itemPayment.currency.currency_iso }}):</td>
                            <td>{{itemPayment.amount_payment}}</td>
                          </tr>
                          <tr>
                            <td>Efectivo proporcionado ({{ itemPayment.currency.currency_iso }}):</td>
                            <td>{{itemPayment.cash_provided}}</td>
                          </tr>
                          <tr>
                            <td>Cambio calculado a devolver ({{ itemPayment.currency.currency_iso }}):</td>
                            <td>{{itemPayment.cash_change_calculated}}</td>
                          </tr>
                          <tr v-if="item.currency.id !== 1">
                            <td>Tipo de cambio usado (MXN):</td>
                            <td>{{item.change_currency.current_amount_mxn}}</td>
                          </tr>
                          <tr v-if="itemPayment.currency.id !== 1">
                            <td>Cambio devuelto (MXN):</td>
                            <td>{{itemPayment.cash_change_calculated_mxn}}</td>
                          </tr>
                          <tr v-if="itemPayment.currency.id !== 1">
                            <td>Monto cobrado en (MXN):</td>
                            <td>{{itemPayment.amount_payment_converted_mxn}}</td>
                          </tr>
                        </template>
                      </template>
                    </table>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </td>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<style scoped>
.inner-table td:first-child {
  margin-top: 1.2em;
}
.inner-table {
  border-collapse: collapse;
}
.inner-table td {
  padding: 1.2em;
  border: 1px solid #f2f2f2;
}
</style>
<script>
export default {
  name: "ReportSales",
  data() {
    return {
      expanded: [],
      headersReport: [
        {
          text: '#',
          value: 'invoice_string_id',
        },
        {
          text: 'Monto total',
          value: 'amount_mxn',
        },
        {
          text: 'Moneda',
          value: 'currency.currency_iso',
        },
        {
          text: '¿Generó comisión?',
          value: 'guide_commission_amount',
        },
        {
          text: 'Fecha y hora',
          value: 'created_at',
        },
      ],
      itemsReport: [],
      reportDates: {
        startDate: '2024-06-27',
        endDate: '2024-07-01',
      },
      loadingResults: false,
    }
  },
  mounted() {
    this.getSalesReport()
  },
  methods: {
    getInvoicePdfUrl(item){
      return `${this.$apiUrl}/invoice-manager/generate-invoice-pdf/${item.id}#zoom=250`
    },
    getSalesReport(){
      if(
          this.reportDates.startDate.trim().length <= 0||
          this.reportDates.endDate.trim().length <= 0
      ) {
        alert('Favor de ingresar fechas válidas')
        return false
      }

      this.loadingResults = true
      this.$http.post(`${this.$apiUrl}/reports-manager/get-sales-shopping-cart`, {
        'date-from': this.reportDates.startDate,
        'date-to': this.reportDates.endDate,
      })
          .then((res) => {
            this.itemsReport = res.data.data
          })
          .finally(() => {
            this.loadingResults = false
          })
    },
  },
}
</script>