<template>
  <div>

    <h1 class="text-h2 text--secondary">
      <v-icon x-large aria-hidden="false"> mdi-shopping-outline</v-icon>
      Caja
    </h1>

    <!--Dialog for adding ITEMS TO CART -->
    <v-dialog
        v-model="dialogPagarVisita"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>Pagar visita a guía</v-card-title>
        <v-card-text>


          <v-alert type="success" outlined dense v-if="showBusinessCodeResultSuccess" dismissible>
            El cliente es válido y se vinculará a la compra una vez finalizada la compra
          </v-alert>
          <div v-if="showGuidePayInvoiceIframe">
            <v-alert color="black" outlined>
              <strong>Atención.</strong> Favor de <strong>imprimir</strong> el recibo generado de esta orden.
              Gracias.
            </v-alert>
            <iframe style="width:100%;height:500px;" :src="getGuidePayUrlInvoice()"></iframe>

            <v-btn
                @click="resetGuidePay"
                color="black"
                elevation="0"
                x-large
                class="white--text mt-3" small>Confirmar y generar otro pago
            </v-btn>

          </div>

          <div v-if="!showGuidePayInvoiceIframe">
            <div v-if="searchingBusinessCode">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              Buscando, espere...
            </div>
            <div v-else>
              <v-text-field
                  hide-details
                  v-model="payVisitGuide.businessCode"
                  label="Ingrese el código de guía o cliente"
                  outlined
                  clearable
              ></v-text-field>
            </div>

            <v-btn
                :disabled="!((!payVisitGuide.guideAgencyFound) && !payVisitGuide.isValidGuide)"
                @click="searchBusinessCodePayVisit"
                color="black"
                elevation="0"
                x-large
                class="white--text mt-3" small>Validar número de guía o agente
            </v-btn>

            <div v-if="payVisitGuide.guideAgencyFound">
              <v-simple-table>
                <thead>
                <tr>
                  <th>Nombre guía</th>
                  <th>Pago por visita</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ hideSensitiveString(payVisitGuide.guideAgencyFound.guide_name) }}</td>
                  <td>{{payVisitGuide.guideAgencyFound.guide_commission_visit_amount}}</td>
                </tr>

                <tr>
                  <td colspan="4"><small>* Los montos son expresados en pesos mexicanos y puede cambiar sin previo aviso</small></td>
                </tr>
                <tr>
                  <td colspan="4"><small>* Algunos datos en esta tabla podrían ocultarse por motivos de privacidad y se requieren permisos adicionales para mostrarlos</small></td>
                </tr>

                </tbody>

              </v-simple-table>

              <v-btn
                  :disabled="loadingPayGuideVisit"
                  @click="confirmPayGuideVisit"
                  color="black"
                  elevation="0"
                  x-large
                  class="white--text mt-3" small>Realizar pago de visita por ${{payVisitGuide.guideAgencyFound.guide_commission_visit_amount}} MXN
              </v-btn>
              <div v-if="loadingPayGuideVisit">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                Generando pago, espere...
              </div>
          </div>

          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogPagarVisita = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialog for adding ITEMS TO CART -->
    <v-dialog
        v-model="dialogAddItemCart"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>Agregar items al carrito de compra</v-card-title>
        <v-card-actions>
          <v-btn text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
        v-model="dialogCurrency"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>Configurar tipo de cambio</v-card-title>
        <v-card-text>
          <v-alert type="info"><strong>Atención.</strong> Capture en este formulario el valor de las monedas. Estos
            valores por moneda se utilizarán al momento del cobro en la caja.
          </v-alert>
          <!--mxn-->
          <v-row>
            <v-col lg="4">
              <label for="">mxn (Pesos Mexicanos) - Default</label>
              <p class="ma-0 pa-0 font-weight-bold text-h6">1 MXN equivale a</p>
            </v-col>
            <v-col lg="4">
              <v-text-field v-model="changesCurrencyMXN.amount" label="Pesos mexicanos" type="number"
                            placeholder="Cantidad MXN"></v-text-field>
            </v-col>
            <v-col lg="4">
              <v-btn @click="storeChangeCurrency(1)" class="mt-2" color="green" outlined>Guardar MXN</v-btn>
            </v-col>
          </v-row>
          <!--USD-->
          <v-row>
            <v-col lg="4">
              <label for="">USD (Dólares)</label>
              <p class="ma-0 pa-0 font-weight-bold text-h6">1 USD equivale a</p>
            </v-col>
            <v-col lg="4">
              <v-text-field v-model="changesCurrencyUSD.amount" label="Pesos mexicanos" type="number"
                            placeholder="Cantidad MXN"></v-text-field>
            </v-col>
            <v-col lg="4">
              <v-btn @click="storeChangeCurrency(2)" class="mt-2" color="green" outlined>Guardar USD</v-btn>
            </v-col>
          </v-row>
          <!--EUR-->
          <v-row>
            <v-col lg="4">
              <label for="">EUR (Euros)</label>
              <p class="ma-0 pa-0 font-weight-bold text-h6">1 EUR equivale a</p>
            </v-col>
            <v-col lg="4">
              <v-text-field v-model="changesCurrencyEUR.amount" label="Pesos mexicanos" type="number"
                            placeholder="Cantidad MXN"></v-text-field>
            </v-col>
            <v-col lg="4">
              <v-btn @click="storeChangeCurrency(3)" class="mt-2" color="green" outlined>Guardar EUR</v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-2"></v-divider>
          <p class="text-h5 blue--text">Historial de tipos de cambio</p>
          <v-data-table :items="historyCurrencyChanges" :headers="headersHistoryCurrency"></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogCurrency = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialog for capturing FINANCIAL CONCEPTS-->
    <v-dialog
        v-model="dialogConcepts"
        persistent
        max-width="800px">
      <v-card>
        <v-card-title>Crear nuevo concepto</v-card-title>
        <v-card-text>

          <v-alert type="info" outlined>Los conceptos que capture y administre aquí, se verán reflejados en el área de
            caja. Para eliminar o editar,
            <a href="#!">visite el módulo de conceptos</a>
          </v-alert>
          <v-alert v-model="showSavedMessage" type="success" dismissible>El concepto se creó correcamente</v-alert>
          <v-alert v-model="showErrorMessage" type="error" dismissible>Ocurrió un error al guardar, por favor inténtelo
            de nuevo más tarde
          </v-alert>
          <v-text-field v-model="financialConceptForm.conceptName" label="Por favor captura el nombre del concepto"
                        placeholder="Ejemplo: TAZA DE VIDRIO BLANCA"></v-text-field>
          <v-text-field v-model="financialConceptForm.conceptPriceMxn" type="number" step="0.01"
                        label="Precio expresado en MXN"></v-text-field>
          <v-textarea v-model="financialConceptForm.conceptDetails" :rows="5"
                      placeholder="Ejemplo: TAZA FABRICADA DE VIDRIO DE 20X20cm COLOR AMARILLA"
                      label="Descripción del concepto (opcional)"></v-textarea>
          <v-btn v-if="!loadingStoreConcept" color="primary" @click="storeFinancialConcept">Guardar</v-btn>

          <v-progress-linear
              v-if="loadingStoreConcept"
              indeterminate
              color="primary"
          ></v-progress-linear>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogConcepts = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="mt-3">
      <v-col lg="8">
        <div class="mb-5">
          <v-btn color="grey darken-3" outlined @click="dialogConcepts = true">
            <v-icon left>
              mdi-pencil
            </v-icon>
            Crear concepto
          </v-btn>
          <v-btn color="grey darken-3" outlined @click="dialogCurrency = true" class="ml-3">
            <v-icon left>
              mdi-currency-usd
            </v-icon>
            Configurar tipo de cambio
          </v-btn>
          <v-btn color="grey darken-3" outlined @click="openOpenOrderModal" class="ml-3">
            <v-icon left>
              mdi-open-in-new
            </v-icon>
            Abrir orden de compra
          </v-btn>
          <v-btn color="grey darken-3" outlined @click="openPagarVisitaModal" class="ml-3">
            <v-icon left>
              mdi-cash-100
            </v-icon>
            Pagar visita
          </v-btn>
        </div>

        <div v-if="currenciesChanges && currenciesChanges.length > 0">
          <v-card width="280px" class="  d-inline-flex mr-5 mb-5" elevation="2">
            <v-card-text class="light-blue--text text--darken-4">
              <p class="text-h5">Compra dólar</p>
              <p class="text-h3">${{ currenciesChanges[0].current_amount_mxn }} <small>MXN</small></p>
            </v-card-text>
          </v-card>

          <v-card width="280px" class="  d-inline-flex mr-5 mb-5" elevation="2">
            <v-card-text class="light-blue--text text--darken-4">
              <p class="text-h5">Compra euro</p>
              <p class="text-h3">${{ currenciesChanges[1].current_amount_mxn }} <small>MXN</small></p>
            </v-card-text>
          </v-card>
        </div>
        <div v-else>
          <p>No se han definido monedas. Favor de definir al menos una desde la opción de "Configurar tipo de
            cambio"</p>
        </div>

        <div class="order-container-details" v-if="invoiceOrderNumberFound && invoiceOrderNumberFound.trim().length > 0">
          <h1 class="text--h1 mt-2 mb-2 red--text">Orden abierta: #{{invoiceOrderNumberFound}}</h1>
        </div>

        <v-card>
          <v-card-title>Nueva compra</v-card-title>
          <v-card-text>
            <v-alert type="info" outlined>Para empezar a agregar productos al carrito debe buscarlos en el buscador y
              seleccionar el de su preferencia
            </v-alert>
            <!--            <v-btn @click="addItemToCart" color="grey darken-2" class="white&#45;&#45;text" outlined>Agregar al carrito</v-btn>-->
            <v-autocomplete
                v-model="autocompleteSearch"
                hide-no-data
                hide-selected
                :items="itemsConcepts"
                item-value="id"
                item-text="concept_name"
                :search-input.sync="searchInput"
                filled
                label="Escriba el nombre del concepto para agregarlo posteriormente al carrito"
                return-object
            ></v-autocomplete>

            <p class="mt-5" v-if="!itemsCart || itemsCart.length <= 0">El carrito de compras está vacío</p>

            <div v-if="itemsCart && itemsCart.length > 0">
              <p class="subtitle-1 blue--text">Usted agregó <strong>(1) productos</strong> al carrito</p>

              <v-simple-table>
                <thead>
                <tr>
                  <th class="text-left text-h6">Producto</th>
                  <!--                  <th class="text-left text-h6">Descripción</th>-->
                  <th class="text-left text-h6">Cantidades</th>
                  <th class="text-left text-h6">Precio</th>
                  <th class="text-left text-h6">Subtotal</th>
                  <th class="text-left text-h6">Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(itemC, i) in itemsCart">
                  <td class="text-h6">{{ itemC.concept_name }}</td>
                  <!--                  <td class="text-h6">{{ itemC.concept_details }}</td>-->
                  <td class="text-h6">
                    <v-select @change="updateAmountItemCart(itemC, i)" v-model="itemC.quantities"
                              :items="quantitiesDefaultSelect"></v-select>
                  </td>
                  <td class="text-h6">{{ itemC.concept_price_mxn }}</td>
                  <td class="text-h6">{{ itemC.amount_item }}</td>
                  <td class="text-h6">
                    <v-btn @click="deleteItemCart(itemC, i)" icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </div>

          </v-card-text>
        </v-card>


        <v-card class="mt-3 grey lighten-5" elevation="1">
          <v-card-text>
            <v-simple-table class="grey lighten-5">
              <tbody>
              <tr>
                <td class="text-h5 font-weight-light">Subtotal:</td>
                <td class="text-h5 font-weight-light">
                  {{ number_format(cartAmounts.totalOneAmount, 2, '.', ',') }}
                </td>
              </tr>
<!--              <tr>
                <td class="text-h5 font-weight-light">
                  <v-checkbox disabled v-model="activeIVATax" label="">
                    <template v-slot:label>
                      <div class="font-weight-light">
                        <span class="text-h5">IVA (16%)</span> <small>Se requieren de permisos para deshabilitar esta
                        opción</small>
                      </div>
                    </template>
                  </v-checkbox>
                </td>
                <td class="text-h5 font-weight-light">
                  {{ number_format(cartAmounts.amountTaxes, 2, '.', ',') }}
                </td>
              </tr>-->
              <tr>
                <td class="text-h5 font-weight-light">Total:</td>
                <td class="text-h5 font-weight-light">
                  {{ number_format(cartAmounts.totalTwoAmount, 2, '.', ',') }}
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>

        </v-card>


        <v-btn :disabled="(itemsCart.length <= 0)" @click="openFinalizarCompraDialog" color="info" x-large class="mt-5">
          Confirmar compra
        </v-btn>


      </v-col>
      <v-col lg="4">

        <v-card class="mt-5" elevation="1">
          <v-card-title class="light-blue--text text--darken-4">Últimas compras</v-card-title>
          <v-card-text>
            <div v-if="loadingFinishedOrders">
              <v-progress-linear
                  indeterminate
                  color="primary"
              ></v-progress-linear>
            </div>

            <div v-else>
              <div v-if="(!lastFinishedOrders || lastFinishedOrders.length <= 0)">
                No se encontraron órdenes finalizadas
              </div>

              <v-list v-for="(itemOrder, i) in lastFinishedOrders">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title> {{moment(itemOrder.created_at).format('LLL')}} • <small>{{itemOrder.invoice_string_id}}</small></v-list-item-title>
                    <v-list-item-title>
                      <strong>${{number_format(itemOrder.amount_original, 2, '.', ',')}} MXN</strong> <span class="light-blue--text text--darken-4">{{
                        (itemOrder.currency.id !== 1) ? ` • $${itemOrder.amount_converted} ${itemOrder.currency.currency_iso}` : ''
                      }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="( (i +1) <= 5)"></v-divider>
              </v-list>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-5" elevation="1">
          <v-card-title class="light-blue--text text--darken-4">Órdenes de compra</v-card-title>
          <v-card-text>
            <div v-if="loadingOpenedOrders">
              <v-progress-linear
                  indeterminate
                  color="primary"
              ></v-progress-linear>
            </div>
            <div v-else>
              <div v-if="(!lastOpenedOrders || lastOpenedOrders.length <= 0)">
                No se encontraron órdenes abiertas
              </div>
              <v-list v-for="(itemOrder, i) in lastOpenedOrders">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{moment(itemOrder.created_at).format('LLL')}} • <small>Orden pendiente</small></v-list-item-title>
                    <v-list-item-content>
                      <p class="display-1">{{ itemOrder.order_opened_id }}</p>
                    </v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="( (i +1) <= 5)"></v-divider>
              </v-list>
            </div>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <v-dialog persistent v-model="dialogOpenOrder" max-width="800px">
      <v-card>
        <v-card-title>Abrir orden de compra</v-card-title>
        <v-card-text>

          <v-alert v-if="showOrderNoFoundError" type="error" outlined>No se encontraron coincidencias con este número de orden</v-alert>

          <v-text-field v-model="invoiceOrderNumber" label="Ingresa el número de orden" placeholder="Ej: OOR_0000001"></v-text-field>
          <v-btn @click="searchOrderShoppingCart" :disabled="loadingSearchingOrder" color="black" class="white--text" x-large>Confirmar y reabrir orden</v-btn>

          <div v-if="loadingSearchingOrder">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
            Buscando, espere...
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogOpenOrder = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="ordenCompraDialog" max-width="800px">
      <v-card>
        <v-card-title>Orden de compra</v-card-title>
        <v-card-text>
          <v-alert color="black" outlined>
            <strong>Atención.</strong> Por favor tenga en cuenta lo siguiente antes de crear una orden de compra:
            <ol>
              <li>La orden de compra estará vigente hasta 48 horas, luego de ese periodo se necesitará crear un pedido
                nuevo
              </li>
              <li>Al crear una orden de compra deberá volver a capturar los montos de pago, así como las exhibiciones y
                sus montos
              </li>
              <li>Utilice las órdenes de compra como última alternativa de venta</li>
              <li>El sistema le proporcionará un folio único a través de un ticket que podrá imprimir y proporcionar al
                cliente. El cliente deberá presentar este ticket para poder abrir el pedido nuevamente desde la caja.
              </li>
            </ol>
          </v-alert>

          <v-textarea
              counter
              v-model="orderShoppingCart.additionalComments"
              :rules="rulesCommentsOrder"
              outlined
              name="input-7-4"
              label="Agregue alguna nota adicional si lo necesita o deje como está para continuar"
          ></v-textarea>

          <v-btn
              :disabled="loadingOrderCreation"
              @click="confirmOrderShoppingCart"
              color="light-blue darken-4"
              class="white--text"
              x-large outlined>Proceder a crear la orden de compra
          </v-btn>

        </v-card-text>
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn @click="ordenCompraDialog = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="finalizarCompraDialog" max-width="800px">
      <v-card>
        <v-card-title>Finalizar compra</v-card-title>
        <v-card-text>

          <div v-if="!orderClosed() && !invoiceDetailsCreated">
            <p><a @click="showHideOrderResume = !showHideOrderResume" href="#!">Mostrar/Ocultar Resumen de Compra</a></p>
            <div v-if="showHideOrderResume">
              <v-simple-table>
                <thead>
                <tr>
                  <td colspan="4">Detalles en pesos mexicanos</td>
                </tr>
                <tr>
                  <th>Descripción</th>
                  <th>Unidades</th>
                  <th>Precio</th>
                  <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in itemsCart">
                  <td>{{ item.concept_name }}</td>
                  <td>{{ item.quantities }}</td>
                  <td>{{ item.concept_price_mxn }}</td>
                  <td>{{ number_format(parseFloat(item.concept_price_mxn * item.quantities), 2, '.', ',') }}</td>
                </tr>
                </tbody>
              </v-simple-table>
              <v-divider></v-divider>
              <v-simple-table>
                <tbody>
                <tr class="grey lighten-3">
                  <td>Subtotal:</td>
                  <td>{{ number_format(cartAmounts.totalOneAmount, 2, '.', ',') }}</td>
                </tr>
<!--                <tr class="grey lighten-3">
                  <td>Impuestos:</td>
                  <td>{{ number_format(cartAmounts.amountTaxes, 2, '.', ',') }}</td>
                </tr>-->
                <tr class="grey lighten-3">
                  <td>Total:</td>
                  <td>{{ number_format(cartAmounts.totalTwoAmount, 2, '.', ',') }}</td>
                </tr>
                </tbody>
              </v-simple-table>
              <div>
                <v-simple-table>
                  <thead>
                  <tr>
                    <td colspan="4">Detalles en moneda {{ paymentDetails.currencyTransaction.currency_iso }}</td>
                  </tr>
                  <tr>
                    <th>Descripción</th>
                    <th>Unidades</th>
                    <th>Precio</th>
                    <th>Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in itemsCart">
                    <td>{{ item.concept_name }}</td>
                    <td>{{ item.quantities }}</td>
                    <td>{{ item.concept_price_converted_unit }}</td>
                    <td>{{
                        number_format(parseFloat(item.concept_price_converted_unit * item.quantities), 2, '.', ',')
                      }}
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
                <v-divider></v-divider>
                <v-simple-table>
                  <tbody>
                  <tr class="grey lighten-3">
                    <td>Subtotal:</td>
                    <td>{{
                        number_format(getAmountWithConversion(this.cartAmounts.totalOneAmount, this.paymentDetails.currencyTransaction), 2, '.', ',')
                      }}
                    </td>
                  </tr>
                  <tr class="grey lighten-3">
                    <td>Impuestos:</td>
                    <td>{{
                        number_format(getAmountWithConversion(this.cartAmounts.amountTaxes, this.paymentDetails.currencyTransaction), 2, '.', ',')
                      }}
                    </td>
                  </tr>
                  <tr class="grey lighten-3">
                    <td>Total:</td>
                    <td>{{
                        number_format(getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction), 2, '.', ',')
                      }}
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </div>

            <p class="pt-6 text-h4 light-blue--text text--darken-4 font-weight-bold">Detalles del comprador</p>

            <v-alert type="error" outlined dense v-if="showBusinessCodeResultError" dismissible>
              No se encontraron coincidencias con el código de cliente ingresado
            </v-alert>
            <v-radio-group
                label="¿Esta compra debe vincularse a un guía o agencia?"
                v-model="paymentDetails.isOrderByGuideAgency"
                row
            >
              <v-radio
                  label="Sí"
                  :value="1"
              ></v-radio>
              <v-radio
                  label="No"
                  :value="2"
              ></v-radio>
            </v-radio-group>
            <div v-if="paymentDetails.isOrderByGuideAgency === 1">

              <v-alert type="success" outlined dense v-if="showBusinessCodeResultSuccess" dismissible>
                El cliente es válido y se vinculará a la compra una vez finalizada la compra
              </v-alert>

              <v-btn
                  @click="searchBusinessCode"
                  color="black" elevation="0" class="white--text mb-1" small>Validar código
              </v-btn>

              <div v-if="searchingBusinessCode">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
                Buscando, espere...
              </div>
              <div v-else>
                <v-text-field
                    v-model="paymentDetails.agencyGuideBusinessCode"
                    label="Ingrese el código de guía o cliente"
                    outlined
                    clearable
                ></v-text-field>
              </div>

              <div v-if="paymentDetails.guideAgencyFound">
                <p>A continuación se desglosa un ejemplo para calcular la comisión de este guía: <strong v-html="paymentDetails.agencyGuideBusinessCode"></strong>:</p>
                <v-simple-table>
                  <thead>
                  <tr>
                    <th>Monto de compra</th>
                    <th>Nombre guía</th>
                    <th>Comisión por venta</th>
                    <th>Total comisión</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>${{ number_format(cartAmounts.totalTwoAmount, 2, '.', ',') }}</td>
                    <td>{{ hideSensitiveString(paymentDetails.guideAgencyFound.guide_name) }}</td>
                    <td>***</td>
                    <td>${{ number_format(getAmountComission(), 2, '.', ',') }}</td>
                  </tr>

                  <tr>
                    <td colspan="4"><small>* Los montos son expresados en pesos mexicanos y puede cambiar sin previo aviso</small></td>
                  </tr>
                  <tr>
                    <td colspan="4"><small>* Algunos datos en esta tabla podrían ocultarse por motivos de privacidad y se requieren permisos adicionales para mostrarlos</small></td>
                  </tr>

                  </tbody>

                </v-simple-table>
              </div>
            </div>

            <p class="pt-6 text-h4 light-blue--text text--darken-4 font-weight-bold">Forma de pago</p>
            <v-alert type="info" outlined dismissible dense>
              Por cada exhibición deberá especificar el monto y método de pago. Tenga en cuenta que si modifica el número
              de exhibiciones <strong>tendrá que volver a capturar los datos de pago nuevamente</strong>
            </v-alert>

            <v-select
                label="¿En cuántas exhibiciones se realizará el pago?"
                v-model="paymentDetails.numberPayments"
                :items="numberPayments"
            >
            </v-select>

            <v-select
                label="Seleccione la moneda para esta transacción"
                v-model="paymentDetails.currencyTransaction"
                item-text="currency_iso"
                item-value="id"
                :items="activeCurrencies"
                return-object
            >
            </v-select>

            <v-btn @click="confirmNumberPayments" color="light-blue darken-4" outlined>Continuar</v-btn>

            <div class="mt-6 payments-container" v-if="paymentDetails.numberPayments > 0 && proceedPaymentDetailsForm">

              <div>
                <v-alert dense type="info" dismissible outlined>
                  <strong>Atención: </strong> A continuación se realizará la <strong>conversión</strong> de la moneda
                  elegida a pesos mexicanos. El sistema hará automáticamente la conversión en base a la moneda del cliente
                  y solicitá el número de dólares o euros necesarios para cubrir el monto total de la compra.
                </v-alert>
                <v-simple-table v-if="paymentDetails.currencyTransaction && paymentDetails.currencyTransaction.id !== 1">
                  <thead>
                  </thead>
                  <tbody>
<!--                  <tr>
                    <td>
                      <p class="text-h6 mt-2 mb-2 light-blue&#45;&#45;text text&#45;&#45;darken-4">Desglose de totales (para otras monedas)</p>

                      <p>
                        <strong>1 {{ paymentDetails.currencyTransaction.currency_iso }}</strong> equivale a
                        <strong>{{ paymentDetails.currencyTransaction.change_currency.current_amount_mxn }} pesos
                          mexicanos</strong>
                      </p>

                      <p>Impuestos: {{
                          getAmountWithConversion(cartAmounts.amountTaxes, paymentDetails.currencyTransaction)
                        }} {{ paymentDetails.currencyTransaction.currency_iso }}</p>
                      <p>Subtotal: {{
                          getAmountWithConversion(cartAmounts.totalOneAmount, paymentDetails.currencyTransaction)
                        }} {{ paymentDetails.currencyTransaction.currency_iso }}</p>
                      <p>Total: {{
                          getAmountWithConversion(cartAmounts.totalTwoAmount, paymentDetails.currencyTransaction)
                        }} {{ paymentDetails.currencyTransaction.currency_iso }}</p>

                      <p><strong>{{ cartAmounts.totalTwoAmount }} MXN</strong> equivale a <strong>{{
                          getAmountWithConversion(cartAmounts.totalTwoAmount, paymentDetails.currencyTransaction)
                        }} {{ paymentDetails.currencyTransaction.currency_iso }}</strong></p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <p class="text-h6 mt-2 mb-2 light-blue&#45;&#45;text text&#45;&#45;darken-4">
                        El cliente deberá pagar <strong>{{
                          `${getAmountWithConversion(cartAmounts.totalTwoAmount, paymentDetails.currencyTransaction)} ${paymentDetails.currencyTransaction.currency_iso}`
                        }}</strong>
                      </p>
                    </td>
                  </tr>-->
                  </tbody>
                </v-simple-table>
              </div>


              <v-card class="mb-5" v-for="(itemPayment, index) in paymentDetails.payments" elevation="1">
                <v-card-title>
                  Pago #{{ index + 1 }}:
                </v-card-title>
                <v-card-subtitle>
                  Especifique la forma de pago del pago #{{ index + 1 }}:
                  <span
                      @click="calculateRemainsAmount(index)"
                      v-if="paymentDetails.payments.length > 1 && index !== 0"><a href="#!">Calcular restante</a></span>
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <v-subheader>
                        Cantidad a pagar:
                      </v-subheader>
                    </v-col>
                    <v-col cols="8">

                      <v-text-field
                          label="Cantidad a liquidar en este pago"
                          :disabled="paymentCannotBeChanged()"
                          @change="saveAmountsConvertedPaymentAmount(itemPayment, index)"
                          v-model="itemPayment.paymentAmount"
                          prefix="$"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="(parseInt(itemPayment.paymentMethod) === 1)">
                    <v-col cols="4">
                      <v-subheader>
                        Cantidad en efectivo proporcionada:
                      </v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                          v-model="itemPayment.cashAmountProvided"
                          @keyup="calculateChangeCash()"
                          label="Dinero en efectivo"
                          prefix="$"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="(parseInt(itemPayment.paymentMethod) === 1)">
                    <v-col cols="4">
                      <v-subheader>
                        Cambio en efectivo calculado en {{paymentDetails.currencyTransaction.currency_iso}}:
                      </v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                          :value="itemPayment.changeCalculated"
                          @keyup="calculateChangeCash()"
                          label="Cambio"
                          prefix="$"
                          disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div
                      v-if="(parseInt(paymentDetails.currencyTransaction.id) !== 1)">
                    <v-row>
                      <v-col cols="4">
                        <v-subheader>
                          Cambio equivalente en pesos mexicanos
                        </v-subheader>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                            v-model="itemPayment.changeCalculatedMxn"
                            label="Cambio"
                            prefix="$"
                            disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row >
                      <v-col><small>* Este pago de {{ `$${itemPayment.paymentAmount} ${paymentDetails.currencyTransaction.currency_iso}` }} al tipo de cambio en MXN será transaccionado en ${{ getAmountConversionToMXN(itemPayment.paymentAmount, paymentDetails.currencyTransaction) }} MXN</small></v-col>
                    </v-row>
                  </div>
                  <v-radio-group
                      @change="saveAmountsConvertedPaymentAmount(itemPayment, index)"
                      v-model="itemPayment.paymentMethod"
                      row
                  >
                    <v-radio
                        :disabled="(index!==0)"
                        label="Efectivo"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="Tarjeta de Débito"
                        :value="2"
                    ></v-radio>
                    <v-radio
                        label="Tarjeta de Crédito"
                        :value="3"
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>

              <div>
                <v-alert color="black" class="white--text">
                  Por favor especifique los totales en cada pago. Todos deben sumar la cantidad total a cobrar que es de
                  <strong>${{
                      `${getAmountWithConversion(cartAmounts.totalTwoAmount, paymentDetails.currencyTransaction)} ${paymentDetails.currencyTransaction.currency_iso}`
                    }}</strong>
                </v-alert>

                <v-textarea
                    counter
                    v-model="paymentDetails.orderComments"
                    :rules="rulesCommentsOrder"
                    outlined
                    name="input-7-4"
                    label="¿Desea agregar alguna noticia adicional?"
                ></v-textarea>

              </div>
            </div>
          </div>


          <div class="mt-6">
            <div v-if="loadingPaymentCart">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              Procesando, espere por favor
            </div>

            <div v-if="paymentDetails.numberPayments > 0 && proceedPaymentDetailsForm">
              <div v-if="!loadingPaymentCart && !orderClosed()">
                <v-btn @click="confirmAndFinishOrder()"
                       :disabled="!canProceedPaymentConfirmation()"
                       color="black"
                       outlined
                       x-large>Confirmar y finalizar compra
                </v-btn>

                <v-btn
                    :disabled="(invoiceIdOpenedFound > 0)"
                    elevation="0"
                    class="white--text ml-3"
                    color="black"
                    @click="openOrdenCompraDialog"
                    x-large>Crear orden de compra
                </v-btn>
                <div class="pt-3" v-if="(invoiceIdOpenedFound > 0)">
                  <v-divider class="mb-3"></v-divider>
                  No se puede crear una orden de compra ya que esta orden fue abierta como una orden de compra previamente. Finalice la compra ahora.
                </div>
              </div>
            </div>



            <div v-if="orderClosed() && invoiceDetailsCreated">
              <v-alert color="black" outlined>
                <strong>Atención.</strong> Favor de <strong>imprimir</strong> el recibo generado de esta orden.
                Gracias.
              </v-alert>
              <iframe style="width:100%;height:500px;" :src="getOrderTicketPdfUrl()"></iframe>
            </div>
          </div>



        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="finalizarCompraDialog = false" text>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

export default {
  name: 'ShoppingCart',
  watch: {
    'paymentDetails.isOrderByGuideAgency': function (val, oldVal) {
      // No
      console.log(val, oldVal)
      if (val === 2) {
        this.paymentDetails.agencyGuideBusinessCode = ''
      }
    },
    /*'paymentDetails.numberPayments': function(val, oldVal) {

    },*/
    autocompleteSearch: function (val, oldVal) {

      // this function is used to add item to a shoppingcart
      this.currentSearchItem = val
      if (val != oldVal) {
        console.log('add to shoppingcart')
        console.log(this.currentSearchItem)
        this.itemsCart.push({
          concept_id: this.currentSearchItem.id,
          concept_name: this.currentSearchItem.concept_name,
          concept_details: this.currentSearchItem.concept_details,
          concept_price_mxn: this.currentSearchItem.concept_price_mxn,
          quantities: 1,
          amount_item: 0,
        })

        // update last item shoppingcart amount
        this.updateAmountItemCart(this.itemsCart[this.itemsCart.length - 1], this.itemsCart.length - 1)

      }
    },
    searchInput: function (val, oldVal) {
      console.log(val)
      this.$http.get(`${this.$apiUrl}/financial-concepts?q=` + val)
          .then((res) => {
            this.itemsConcepts = res.data.data
          })
    }
  },
  data() {
    return {
      showGuidePayInvoiceIframe:false,
      loadingPayGuideVisit:false,
      payVisitGuide: {
        guidePayGenerated: null,
        isValidGuide: false,
        businessCode: '',
        guideAgencyFound: null
      },
      showOrderNoFoundError: false,
      loadingSearchingOrder: false,
      dialogOpenOrder: false,
      loadingOrderCreation: false,
      orderShoppingCart: {
        additionalComments: 'Sin comentarios.',
      },
      showBusinessCodeResultError: false,
      showBusinessCodeResultSuccess: false,
      searchingBusinessCode: false,
      rulesCommentsOrder: [v => v.length <= 249 || 'Máximo 249 caracteres (contando espacios)'],
      showHideOrderResume: false,
      proceedPaymentDetailsForm: false,
      numberPayments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      paymentDetails: {
        // No
        guideAmountCommission: 0,
        guideAgencyFound: null,
        agencyGuideBusinessCode: '',
        isOrderByGuideAgency: 2,
        currencyTransaction: null,
        numberPayments: 0,
        payments: [],
        orderComments: 'Sin comentarios.',
      },

      ordenCompraDialog: false,
      finalizarCompraDialog: false,
      currenciesChanges: [],
      headersHistoryCurrency: [
        /*{
          text: 'Moneda',
          value: 'currency_id',
          sortable: true,
        },*/

        {
          text: 'Moneda',
          value: 'currency.currency_iso',
          sortable: true,
        },
        {
          text: 'Valor del cambio',
          value: 'current_amount_mxn',
          sortable: true,
        },
        {
          text: 'Estatus',
          value: 'is_active',
          sortable: true,
        },
        {
          text: 'Fecha del tipo de cambio',
          value: 'created_at',
          sortable: true,
        },
      ],
      historyCurrencyChanges: null,
      dialogCurrency: false,
      activeIVATax: true,
      quantitiesDefaultSelect: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      itemsCart: [],
      currentSearchItem: null,
      autocompleteSearch: null,
      searchInput: null,
      itemsConcepts: null,
      showErrorMessage: false,
      showSavedMessage: false,
      loadingStoreConcept: false,
      financialConceptForm: {
        conceptName: '',
        conceptDetails: 'N/A',
        conceptPriceMxn: 0
      },
      dialogConcepts: false,
      dialogAddItemCart: false,
      cartAmounts: {
        totalOneAmount: 0,
        amountTaxes: 0,
        ivaTaxPercentage: 16,
        totalTwoAmount: 0,
      },
      changesCurrencyMXN: {
        amount: 0,
      },
      changesCurrencyUSD: {
        amount: 0,
      },
      changesCurrencyEUR: {
        amount: 0,
      },
      activeCurrencies: null,
      loadingPaymentCart: false,
      orderOpenedCreated: false,
      orderFinishedVoided: false,
      invoiceDetailsCreated: null,
      invoiceOrderNumber: '',
      invoiceOrderNumberFound: '',
      invoiceIdOpenedFound: 0,
      lastFinishedOrders: [],
      lastOpenedOrders: [],
      loadingFinishedOrders: true,
      loadingOpenedOrders: true,
      dialogPagarVisita:false,
    }
  },
  mounted() {
    this.financialConceptForm.conceptDetails = 'N/A'
    this.getHistoryCurrChanges();
    this.getCurrenciesChanges()
    this.getActiveCurrencies()

    this.getLastFinishedOrders()
    this.getLastOpenedOrders()

    // Run every 10 minutes refresh opened and finished orders
    setInterval(() => {
      this.getLastFinishedOrders()
      this.getLastOpenedOrders()
    }, 600000)
  },
  methods: {

    paymentCannotBeChanged(){
      return ( this.paymentDetails.payments.length === 1 && this.paymentDetails.payments[0].paymentMethod !== 1 )
    },
    saveAmountsConvertedPaymentAmount(itemPayment, index){
      this.paymentDetails.payments[index]['payment_amount_converted'] = this.getAmountConversionToMXN(itemPayment.paymentAmount, this.paymentDetails.currencyTransaction)

    },
    getLastFinishedOrders(){
      this.loadingFinishedOrders = true
      // premier-customers/get-last-finished-orders
      this.$http.get(`${this.$apiUrl}/premier-customers/get-last-finished-orders`)
          .then((res) => {
            this.lastFinishedOrders = res.data.data
          }).catch((e) => {})
          .finally(() => {
            this.loadingFinishedOrders = false
          })
    },
    getLastOpenedOrders(){
      this.loadingOpenedOrders = true
      // premier-customers/get-last-opened-orders
      this.$http.get(`${this.$apiUrl}/premier-customers/get-last-opened-orders`)
          .then((res) => {
            this.lastOpenedOrders = res.data.data
          }).catch((e) => {})
          .finally(() => {
            this.loadingOpenedOrders = false
          })
    },
    hideSensitiveString(string, length = 5){
      return `${string.substring(3, length+2)}******`
    },
    getAmountComission(){
      this.paymentDetails.guideAmountCommission = parseFloat( parseFloat(this.cartAmounts.totalTwoAmount) * (this.paymentDetails.guideAgencyFound.guide_daily_commission_amount/100) ).toFixed(2)
      return this.paymentDetails.guideAmountCommission
    },
    getGuidePayUrlInvoice(){
      return `${this.$apiUrl}/invoice-manager/generate-guide-pay-pdf/${this.payVisitGuide.guidePayGenerated.id}`
    },
    resetGuidePay(){
      this.showGuidePayInvoiceIframe = false
      this.payVisitGuide.guidePayGenerated = null
      this.payVisitGuide.isValidGuide = false
      this.payVisitGuide.businessCode = ''
      this.payVisitGuide.guideAgencyFound = null
    },
    openPagarVisitaModal(){
      this.showGuidePayInvoiceIframe = false
      this.payVisitGuide.guidePayGenerated = null
      this.payVisitGuide.isValidGuide = false
      this.payVisitGuide.businessCode = ''
      this.payVisitGuide.guideAgencyFound = null
      this.dialogPagarVisita = true
    },
    confirmPayGuideVisit(){
      // store
      this.loadingPayGuideVisit = true
      this.$http.post(`${this.$apiUrl}/cart-payments/pay-guide-visit`, {
        guide: this.payVisitGuide.guideAgencyFound
      }).then((res) => {
        if(res.data.success) {
          this.payVisitGuide.guidePayGenerated = res.data.data
          this.showGuidePayInvoiceIframe = true
        }
      }).catch((e) => {
      }).finally(() => {
        this.loadingPayGuideVisit = false
      })
    },
    openOpenOrderModal(){
      this.showOrderNoFoundError = false
      this.invoiceOrderNumberFound = ''
      this.invoiceOrderNumber = ''
      this.invoiceIdOpenedFound = 0
      this.dialogOpenOrder = true
    },
    searchOrderShoppingCart(){

      if(this.invoiceOrderNumber.trim().length <= 0) {
        alert('Orden inválida')
        return false
      }

      this.invoiceOrderNumberFound = ''
      this.invoiceIdOpenedFound = 0
      this.loadingSearchingOrder = true
      this.showOrderNoFoundError = false



      this.$http.post(`${this.$apiUrl}/cart-payments/search-opened-order`, {
        'invoice_order_number': this.invoiceOrderNumber,
      }).then((res) => {
        console.log(res.data)
        if(res.data.success) {
          // order found
          let orderFound = res.data.data
          this.invoiceIdOpenedFound = orderFound.id
          this.invoiceOrderNumberFound = this.invoiceOrderNumber
          this.itemsCart = []
          for(let ic = 0; ic < orderFound.invoice_concepts.length; ic++) {
            this.itemsCart.push({
              concept_id: orderFound.invoice_concepts[ic].financial_concept_id,
              concept_name: orderFound.invoice_concepts[ic].concept_manual_description,
              concept_details: 'N/A',
              concept_price_mxn: orderFound.invoice_concepts[ic].concept_item_amount_original,
              quantities: orderFound.invoice_concepts[ic].concept_quantities,
              amount_item: orderFound.invoice_concepts[ic].concept_total_amount_original,
            })
          }

          this.calculateAmounts()
          // close modal
          this.dialogOpenOrder = false

        } else {
          this.showOrderNoFoundError = true
        }
      }).finally(() => {
        this.loadingSearchingOrder = false
      })
    },
    cleanShoppingCart() {
      this.paymentDetails.guideAgencyFound = null
      this.paymentDetails.agencyGuideBusinessCode = ''
      this.paymentDetails.isOrderByGuideAgency = 2
      this.cartAmounts = {
        totalOneAmount: 0,
        amountTaxes: 0,
        ivaTaxPercentage: 16,
        totalTwoAmount: 0,
      }
      this.invoiceOrderNumber = ''
      this.invoiceOrderNumberFound = ''
      this.invoiceIdOpenedFound = 0
      this.itemsConcepts = []
      this.itemsCart = []
      this.orderOpenedCreated = false
      this.orderFinishedVoided = false
      this.loadingPaymentCart = false
      this.showBusinessCodeResultError = false
      this.showBusinessCodeResultSuccess = false
      this.showHideOrderResume = false
      this.finalizarCompraDialog = true
      this.proceedPaymentDetailsForm = false

      //this.paymentDetails.cashAmountProvided = 0
      //this.paymentDetails.changeCalculated = 0
      this.paymentDetails.numberPayments = 1

      // default currency
      this.paymentDetails.currencyTransaction = this.activeCurrencies[0]

    },
    getOrderTicketPdfUrl() {
      if (this.orderOpenedCreated) {
        return `${this.$apiUrl}/invoice-manager/generate-invoice-order-pdf/${this.invoiceDetailsCreated.id}`
      }
      return `${this.$apiUrl}/invoice-manager/generate-invoice-pdf/${this.invoiceDetailsCreated.id}`
    },
    orderClosed() {
      return (this.orderOpenedCreated || this.orderFinishedVoided)
    },
    getCashChangeConverted() {

      this.paymentDetails.payments[0].changeCalculatedMxn = parseFloat(parseFloat(this.paymentDetails.payments[0].changeCalculated) * parseFloat(this.paymentDetails.currencyTransaction.change_currency.current_amount_mxn)).toFixed(2)
    },
    calculateChangeCash() {

      // calculate cash payment method amount total
      let totalAmountSpecifiedCash = 0,
          cashAmountProvided = 0
      for (let pm = 0; pm < this.paymentDetails.payments.length; pm++) {
        // 1 = Efectivo / Cash
        if (parseInt(this.paymentDetails.payments[pm].paymentMethod) === 1) {
          cashAmountProvided = parseFloat(this.paymentDetails.payments[pm].cashAmountProvided).toFixed(2)
          totalAmountSpecifiedCash = parseFloat(this.paymentDetails.payments[pm].paymentAmount).toFixed(2)
        }
      }
      // difference cash change
      let cashChangeCalculated = parseFloat(cashAmountProvided - totalAmountSpecifiedCash).toFixed(2)
      // set change calculated to item cash type
      this.paymentDetails.payments[0]['changeCalculated'] = (cashChangeCalculated <= 0) ? 0 : cashChangeCalculated
      // save converted amount provided
      // we send 0 because 0 is the first payment in cash
      this.saveAmountsConvertedPaymentAmount(this.paymentDetails.payments[0], 0)


      // calculating change in pesos mexicanos
      this.getCashChangeConverted()
    },
    searchBusinessCodePayVisit() {
      if (this.payVisitGuide.businessCode.trim().length <= 0) {
        alert('Código inválido')
        return false
      }
      this.isValidGuide = false
      this.payVisitGuide.guideAgencyFound = null
      this.showBusinessCodeResultError = false
      this.showBusinessCodeResultSuccess = false
      this.searchingBusinessCode = true
      this.$http.post(`${this.$apiUrl}/premier-customers/search-business-code`, {
        business_code: this.payVisitGuide.businessCode
      }).then((res) => {
        if (res.data.success) {
          this.isValidGuide = true
          this.payVisitGuide.guideAgencyFound = res.data.data
          this.showBusinessCodeResultError = false
          this.showBusinessCodeResultSuccess = true
        } else {
          this.showBusinessCodeResultError = true
          this.showBusinessCodeResultSuccess = false
          this.payVisitGuide.businessCode = ''

          //this.paymentDetails.isOrderByGuideAgency = 2
        }
      }).catch((e) => {

      }).finally(() => {
        this.searchingBusinessCode = false
      })
    },
    searchBusinessCode() {
      if (this.paymentDetails.agencyGuideBusinessCode.trim().length <= 0) {
        alert('Código inválido')
        return false
      }
      this.paymentDetails.guideAgencyFound = null
      this.showBusinessCodeResultError = false
      this.showBusinessCodeResultSuccess = false
      this.searchingBusinessCode = true
      this.$http.post(`${this.$apiUrl}/premier-customers/search-business-code`, {
        business_code: this.paymentDetails.agencyGuideBusinessCode
      }).then((res) => {
        if (res.data.success) {
          this.paymentDetails.guideAgencyFound = res.data.data
          this.showBusinessCodeResultError = false
          this.showBusinessCodeResultSuccess = true
        } else {
          this.showBusinessCodeResultError = true
          this.showBusinessCodeResultSuccess = false
          this.paymentDetails.agencyGuideBusinessCode = ''

          this.paymentDetails.isOrderByGuideAgency = 2
        }
      }).catch((e) => {

      }).finally(() => {
        this.searchingBusinessCode = false
      })
    },
    confirmOrderShoppingCart() {
      let amountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction),
          subAmountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.totalOneAmount, this.paymentDetails.currencyTransaction),
          taxesAmountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.amountTaxes, this.paymentDetails.currencyTransaction),
          amountTransaction = (this.cartAmounts.totalTwoAmount),
          subAmountTransaction = (this.cartAmounts.totalOneAmount),
          taxesAmountTransaction = (this.cartAmounts.amountTaxes)

      this.orderOpenedCreated = false
      this.orderFinishedVoided = false
      this.loadingPaymentCart = true
      this.invoiceDetailsCreated = null

      console.log('amountTransaction-->' + amountTransaction)
      console.log(this.itemsCart)
      console.log(this.paymentDetails)

      this.loadingOrderCreation = true
      this.$http.post(`${this.$apiUrl}/cart-payments/confirm-create-order`, {
        order_opened_comments: this.orderShoppingCart.additionalComments,
        items_cart: this.itemsCart,
        payment_details: this.paymentDetails,
        amountTransactionConverted: amountTransactionConverted,
        subAmountTransactionConverted: subAmountTransactionConverted,
        taxesAmountTransactionConverted: taxesAmountTransactionConverted,
        amountTransaction: amountTransaction,
        subAmountTransaction: subAmountTransaction,
        taxesAmountTransaction: taxesAmountTransaction,
      }).then((res) => {
        if (res.data.success) {

          // clean cart
          this.cleanShoppingCart()

          this.orderOpenedCreated = true
          this.orderFinishedVoided = false
          this.invoiceDetailsCreated = res.data.data
          // close modal
          this.ordenCompraDialog = false
        }
      }).finally(() => {
        this.loadingPaymentCart = false

      })
    },

    validateGuideForm() {

    },

    confirmAndFinishOrder() {



      this.orderOpenedCreated = false
      this.orderFinishedVoided = false
      this.loadingPaymentCart = true
      this.invoiceDetailsCreated = null
      let amountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction),
          subAmountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.totalOneAmount, this.paymentDetails.currencyTransaction),
          taxesAmountTransactionConverted = this.getAmountWithConversion(this.cartAmounts.amountTaxes, this.paymentDetails.currencyTransaction),
          amountTransaction = (this.cartAmounts.totalTwoAmount),
          subAmountTransaction = (this.cartAmounts.totalOneAmount),
          taxesAmountTransaction = (this.cartAmounts.amountTaxes)

      this.$http.post(`${this.$apiUrl}/cart-payments/confirm-finish-order`, {
        invoiceOrderNumberFound: this.invoiceOrderNumberFound,
        invoiceIdOpenedFound: this.invoiceIdOpenedFound,
        items_cart: this.itemsCart,

        payment_details: this.paymentDetails,
        amountTransactionConverted: amountTransactionConverted,
        subAmountTransactionConverted: subAmountTransactionConverted,
        taxesAmountTransactionConverted: taxesAmountTransactionConverted,
        amountTransaction: amountTransaction,
        subAmountTransaction: subAmountTransaction,
        taxesAmountTransaction: taxesAmountTransaction,
      }).then((res) => {
        if (res.data.success) {

          // clean cart
          this.cleanShoppingCart()

          this.orderOpenedCreated = false
          this.orderFinishedVoided = true

          this.invoiceDetailsCreated = res.data.data

          this.getLastFinishedOrders()
          this.getLastOpenedOrders()

        }
      }).finally(() => {
        this.loadingPaymentCart = false

      })

    },
    calculateRemainsAmount(index) {
      let totalAmountSpecified = 0
      for (let pm = 0; pm < this.paymentDetails.payments.length; pm++) {
        totalAmountSpecified += parseFloat(this.paymentDetails.payments[pm].paymentAmount)
      }

      let amountRequiredToPay = this.getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction)

      if (totalAmountSpecified > amountRequiredToPay) {
        this.paymentDetails.payments[index]['paymentAmount'] = 0
        return false
      }

      this.paymentDetails.payments[index]['paymentAmount'] = (parseFloat(amountRequiredToPay) - parseFloat(totalAmountSpecified)).toFixed(2)
      this.saveAmountsConvertedPaymentAmount(this.paymentDetails.payments[index], index)
    },
    getTotalAmountSpecified() {
      let totalAmountSpecified = 0
      for (let pm = 0; pm < this.paymentDetails.payments.length; pm++) {
        //cash
        if (this.paymentDetails.payments[pm].paymentMethod === 1) {
          totalAmountSpecified += parseFloat(this.paymentDetails.payments[pm].cashAmountProvided)
        }
        //other
        else {
          totalAmountSpecified += parseFloat(this.paymentDetails.payments[pm].paymentAmount)
        }

      }
      return totalAmountSpecified
    },
    canProceedPaymentConfirmation() {
      /*let totalAmountSpecified = 0
      for (let pm = 0; pm < this.paymentDetails.payments.length; pm++) {
        totalAmountSpecified += parseFloat(this.paymentDetails.payments[pm].paymentAmount)
      }*/
      let amountRequiredToPay = this.getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction),
          totalAmountSpecified = this.getTotalAmountSpecified()
      console.log(totalAmountSpecified, amountRequiredToPay)

      if (this.paymentDetails.orderComments.trim().length > 249 || this.paymentDetails.orderComments.trim().length <= 0) {
        return false
      }

      return (totalAmountSpecified >= parseFloat(amountRequiredToPay))
    },
    getAmountWithConversion(finalAmount, currentPaymentCurrency) {
      if (currentPaymentCurrency.currency_iso == 'MXN') {
        return finalAmount
      }
      console.log(currentPaymentCurrency.change_currency.current_amount_mxn)

      return parseFloat(finalAmount / currentPaymentCurrency.change_currency.current_amount_mxn).toFixed(2)
    },
    getAmountConversionToMXN(finalAmount, currentPaymentCurrency) {
      if (currentPaymentCurrency.currency_iso == 'MXN') {
        return parseFloat(finalAmount).toFixed(2)
      }
      return parseFloat( parseFloat(finalAmount) * currentPaymentCurrency.change_currency.current_amount_mxn ).toFixed(2)
    },
    confirmNumberPayments() {

      // if guide section is filles but not submitted we alert just in case
      if(this.paymentDetails.agencyGuideBusinessCode.trim().length > 0 || this.paymentDetails.isOrderByGuideAgency === 1) {
        // guide was not validated yet
        if(!this.paymentDetails.guideAgencyFound) {
          let confirmContinue = confirm('Hemos detectado que ingresaste un número de guía pero no se ha validado, ¿deseas continuar sin enlazar esta compra a un guía?')
          if(confirmContinue) {
            this.paymentDetails.agencyGuideBusinessCode = ''
            this.paymentDetails.guideAgencyFound = null
            this.paymentDetails.isOrderByGuideAgency = 2
          }
          else {
            return false
          }
        }
      }


      if (this.proceedPaymentDetailsForm) {
        let confirmChangingPayments = confirm('¿Confirma modificar los pagos? Tendrá que capturarlos de nuevo')
        if (!confirmChangingPayments) {
          return false
        }
      }

      this.proceedPaymentDetailsForm = true
      this.paymentDetails.payments = []
      for (let pm = 0; pm < this.paymentDetails.numberPayments; pm++) {
        // first payment is always in cash, others are different than cash
        this.paymentDetails.payments.push({
          paymentAmount: 0,
          paymentMethod: (pm !== 0) ? 2 : 1,
          cashAmountProvided: 0,
          changeCalculated: 0,
          changeCalculatedMxn: 0,
        })
      }

      // default calculated amount
      // only applies if number of payments is equal to 1
      if (this.paymentDetails.numberPayments === 1) {
        this.paymentDetails.payments[0]['paymentAmount'] = this.getAmountWithConversion(this.cartAmounts.totalTwoAmount, this.paymentDetails.currencyTransaction)
      }

      /*// discounting
      if(oldVal > val) {
        let confirmDelete = confirm('Hemos detectado que intentas eliminar un pago, da click en confirmar si es así o da cancelar para no ejecutar ninguna acción')
        if(confirmDelete) {
          this.paymentDetails.payments.splice(-1)
        }
      }
      // adding
      else {
        this.paymentDetails.payments.push({
          paymentAmount: 0,
          paymentMethod: 1,
        })
      }
      console.log(val, oldVal)*/

      this.updateItemsPricesConverted()
    },
    openOrdenCompraDialog() {
      this.ordenCompraDialog = true
    },
    openFinalizarCompraDialog() {
      this.orderOpenedCreated = false
      this.orderFinishedVoided = false
      this.loadingPaymentCart = false
      this.invoiceDetailsCreated = null

      this.showBusinessCodeResultError = false
      this.showBusinessCodeResultSuccess = false
      this.showHideOrderResume = false
      this.finalizarCompraDialog = true
      this.proceedPaymentDetailsForm = false

      //this.paymentDetails.cashAmountProvided = 0
      //this.paymentDetails.changeCalculated = 0
      this.paymentDetails.numberPayments = 1

      // default currency
      this.paymentDetails.currencyTransaction = this.activeCurrencies[0]

      this.updateItemsPricesConverted()

    },
    updateItemsPricesConverted() {
      // updating prices of items in the currency selectedd
      this.itemsCart.forEach((item, i) => {
        let priceItemConverted = this.getAmountWithConversion(item.concept_price_mxn, this.paymentDetails.currencyTransaction),
            itemQuantitie = parseInt(item.quantities)

        item['concept_price_converted_unit'] = priceItemConverted
        item['concept_price_converted_total'] = priceItemConverted * itemQuantitie
      })
    },
    number_format(number, decimals, dec_point, thousands_point) {

      if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
      }

      if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
      }

      if (!dec_point) {
        dec_point = '.';
      }

      if (!thousands_point) {
        thousands_point = ',';
      }

      number = parseFloat(number).toFixed(decimals);

      number = number.replace(".", dec_point);

      var splitNum = number.split(dec_point);
      splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
      number = splitNum.join(dec_point);

      return number;
    },
    getCurrenciesChanges() {
      this.$http.get(`${this.$apiUrl}/changes-currencies/get-active-changes-currencies`)
          .then((res) => {
            this.currenciesChanges = res.data.data
          }).catch((e) => {

      })
    },
    getActiveCurrencies() {
      this.$http.get(`${this.$apiUrl}/currencies/get-active-currencies`)
          .then((res) => {
            this.activeCurrencies = res.data.data
          }).catch((e) => {

      })
    },
    storeChangeCurrency(currencyId) {
      let amountCurrency = 0
      // USD
      if (currencyId === 2) {
        amountCurrency = this.changesCurrencyUSD.amount
        if (this.changesCurrencyUSD.amount <= 0) {
          alert('Ingrese un valor mayor a cero')
          return false
        }
      }
      // MXN
      else if (currencyId === 1) {
        amountCurrency = this.changesCurrencyMXN.amount
        if (this.changesCurrencyMXN.amount <= 0) {
          alert('Ingrese un valor mayor a cero')
          return false
        }
      }
      // EUR
      else {
        amountCurrency = this.changesCurrencyEUR.amount
        if (this.changesCurrencyEUR.amount <= 0) {
          alert('Ingrese un valor mayor a cero')
          return false
        }
      }

      let confirmSave = confirm('¿Está seguro de guardar? Este nuevo valor se activará y el valor pasado quedará inactivo')
      if (confirmSave) {
        this.$http.post(`${this.$apiUrl}/changes-currencies`, {
          currency_id: currencyId,
          current_amount_mxn: amountCurrency,
        }).then((res) => {
          console.log(res.data)
        }).finally(() => {
          this.getHistoryCurrChanges()
          this.getCurrenciesChanges()
        })
      }
    },
    getHistoryCurrChanges() {
      this.$http.get(`${this.$apiUrl}/changes-currencies`)
          .then((res) => {
            this.historyCurrencyChanges = res.data.data
          })
    },
    calculateAmounts() {
      let localTotalAmount = 0,
          amountTaxes = 0

      this.cartAmounts.amountTaxes = 0
      this.cartAmounts.totalOneAmount = 0
      this.cartAmounts.totalTwoAmount = 0
      if (this.itemsCart && this.itemsCart.length > 0) {
        this.itemsCart.forEach((item, i) => {
          localTotalAmount = localTotalAmount + parseFloat(item.amount_item)
        })

        /*
        // calculate taxes if applies
        if (localTotalAmount > 0 && this.activeIVATax) {
          amountTaxes = parseFloat(localTotalAmount * (this.cartAmounts.ivaTaxPercentage / 100)).toFixed(2)

          this.cartAmounts.amountTaxes = amountTaxes
        }*/

        this.cartAmounts.totalOneAmount = parseFloat(localTotalAmount).toFixed(2)
        this.cartAmounts.totalTwoAmount = (parseFloat(localTotalAmount) + parseFloat(amountTaxes)).toFixed(2)
      }
    },
    updateAmountItemCart(itemCart, i) {
      itemCart['amount_item'] = parseFloat((parseInt(itemCart.quantities) * parseFloat(itemCart.concept_price_mxn))).toFixed(2)

      this.calculateAmounts()
    },
    deleteItemCart(itemCart, i) {
      let deleteConfirm = confirm('¿Quiere eliminar este artículo?')
      if (deleteConfirm) {
        this.autocompleteSearch = null
        this.itemsCart.splice(1, i)
      }
    },
    searchItemsConceptsCart(itemToSearch) {
      console.log(itemToSearch)

    },
    addItemToCart() {
      this.dialogAddItemCart = true
    },

    storeFinancialConcept() {
      this.showErrorMessage = false
      this.showSavedMessage = false
      // store
      this.loadingStoreConcept = true
      this.$http.post(`${this.$apiUrl}/financial-concepts`, {
        concept_name: this.financialConceptForm.conceptName,
        concept_details: this.financialConceptForm.conceptDetails,
        concept_price_mxn: this.financialConceptForm.conceptPriceMxn,
      }).then((res) => {
        this.showSavedMessage = true
        this.financialConceptForm.conceptName = ''
        this.financialConceptForm.conceptDetails = 'N/A'
        this.financialConceptForm.conceptPriceMxn = 0
      }).catch((e) => {
        this.showSavedMessage = false
        this.showErrorMessage = true
      }).finally(() => {
        this.loadingStoreConcept = false

      })
    }
  }
}
</script>
